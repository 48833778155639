<template>
  <div class="promotion">
    <van-sticky>
      <van-config-provider :theme-vars="themeVars">
        <van-nav-bar title="PROMOTION" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
      </van-config-provider>
      <div class="promotion-page-title">BETTING AMOUNT TASK</div>
      <div class="promotion-page-tip">{{appTip}}</div>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="promotion-list">
        <van-list v-if="dataList.length >0">
          <div class="promotion-block" v-for="(item, index) in dataList" :key="item.id">
            <van-image
              class="promotion-img" 
              :src="`/images/promotion/treasure/${index % 8 + 1}${!item.backgroundStatus ? '-disabled' : item.buttonStatus != 0 ? '-open': ''}.png`" 
            />
            <div class="promotion-title">{{item.name}}</div>
            <div class="promotion-time">{{timeStr(item.timestamp)}}</div>
            <div class="promotion-detail" v-for="detail in item.missionDetail" :key="detail.detailCode">
              <p class="promotion-detail-title">{{detail.detailName?.toUpperCase()}}</p>
              <p class="promotion-detail-text">{{detail.explain}}</p>
              <p class="promotion-progress-text">
                <span class="promotion-detail-active">{{detail.current}}</span>
                <span class="promotion-detail-divider">/</span>
                <span>{{detail.target}}</span>
              </p>
              <div class="promotion-progress-wrap">
                <van-progress
                  pivot-text=""
                  stroke-width="6"
                  color="#48dcdd"
                  track-color="#fff"
                  :percentage="Math.floor(detail.current / detail.target * 100)"
                />
                <van-image
                  v-if="detail.current === detail.target"
                  class="promotion-complete-icon" 
                  src="/images/promotion/process-complete.png" 
                />
              </div>
            </div>
            <p v-if="item.buttonStatus === 1" class="promotion-btn" @click="handleReceivePromotion(item)">{{item.buttonName}}</p>
            <p v-else class="promotion-btn disabled">{{item.buttonName}}</p>
          </div>
        </van-list>
        <div v-else>
          <van-empty :description="$t('common.noData')"></van-empty>
        </div>
      </div>
    </van-pull-refresh>
    <van-popup v-model:show="showAllTaskComplete" :close-on-click-overlay="false">
      <div class="complete-dialog">
        <div class="complete-title">CONGRATULATIONS!</div>
        <div class="complete-content">You have completed all the tasks today! Keep doing tomorrow!</div>
        <van-icon class="complete-ok" name="/images/promotion/btn-ok.png" size="44" @click="showAllTaskComplete = false" />
        <van-icon class="dialog-close-icon" name="/images/promotion/btn-close.png" size="24" @click="showAllTaskComplete = false" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import { store } from '@/store';
import { queryPromotionList, receivePromotion, queryPageTip } from "@/api";

export default {
  setup() {
    const refreshing = ref(false);
    const dataList = ref([]);
    
    const themeVars = {
      navBarBackgroundColor: '#f5f5f5',
      navBarTitleTextColor: '#000',
      navBarIconColor: '#000',
    };
    return {
      refreshing,
      dataList,
      themeVars
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      appTip: '',
      showAllTaskComplete: false,
    };
  },
  
  mounted() {
    this.fetchPageTip();
    this.fetchPromotionList();
  },

  methods: {
    fetchPromotionList() {
      queryPromotionList().then(res => {
        this.dataList = res.data || [];
      });
    },
    fetchPageTip() {
      queryPageTip({
        code: 'PromotionFragment',
      }).then(res => {
        this.appTip = res.data.text;
      });
    },
    refreshData() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.fetchPromotionList();
    },
    handleReceivePromotion(item){
      receivePromotion({ id: item.id }).then(res => {
        if (res.data && res.data.last) {
          this.showAllTaskComplete = true;
        }
        this.fetchPromotionList();
        // store.fetchQuickMenu();
      });
    },
    timeStr(time) {
      return moment(time).format('MMM.DD.YYYY');
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.promotion {
  min-height: 100vh;
  background: #f5f5f5;
  .promotion-page-title {
    padding: 4px 16px;
    font-size: 12px;
    font-weight: bold;
    background: #f5f5f5;
  }
  .promotion-page-tip {
    padding: 2px 16px;
    font-size: 8px;
    font-weight: 500;
    background: #f5f5f5;
  }
  .promotion-list {
    padding: 16px;
    .promotion-block {
      position: relative;
      padding: 6px 16px 6px 140px;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 12px;
      text-align: center;
      .promotion-img {
        position: absolute;
        top: -6px;
        left: -6px;
        width: 132px;
      }
    }
    .promotion-title {
      font-size: 10px;
      font-weight: bold;
      color: #666;
      text-align: right;
    }
    .promotion-time {
      font-size: 8px;
      color: #666;
      font-weight: 500;
      text-align: right;
    }
    .promotion-detail {
      text-align: left;
      padding: 10px 0 10px;
      border-bottom: 1px solid var(--van-border-color);
      &:last-of-type {
        border-bottom: none;
      }
      .promotion-detail-title {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
      }
      .promotion-detail-text {
        font-size: 10px;
        line-height: 10px;
        color: #888;
      }
      .promotion-progress-text {
        span {
          font-size: 10px;
          &.promotion-detail-divider {
            margin: 0 2px;
          }
        }
      }
      .promotion-progress-wrap {
        position: relative;
        margin-left: 4px;
        padding: 1px;
        border: 1px solid #000;
        border-radius: 6px;
        .promotion-complete-icon {
          position: absolute;
          top: -9px;
          right: -7px;
          width: 14px;
          
        }
      }
    }
    .promotion-btn {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      background: linear-gradient(180deg, #c4e5f9 0%, #5989b7 100%);
      border: 3px solid #4572a7;
      border-radius: 18px;
      border-top: none;
      &.disabled {
        background: linear-gradient(180deg, #b1b9be 0%, #647a8f 100%);
        border: 3px solid #5f6b73;
        border-top: none;
      }
    }
  }
  .van-popup {
    background: transparent;
  }
  .complete-dialog {
    width: 300px;
    height: 210px;
    text-align: center;
    background: url(/images/promotion/complete-bg.png) no-repeat;
    background-size: 100% auto;
    .complete-title {
      padding: 48px 20px 28px;
      font-size: 20px;
      font-weight: bold;
      color: #6c2904;
    }
    .complete-content {
      line-height: 22px;
      padding: 0 20px;
      font-size: 12px;
      font-weight: 500;
      color: #6c2904;
    }
    .dialog-close-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
